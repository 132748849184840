
import template from './charts-list.html';

/**
 * Charts List VM
 */
class ChartsListPageViewModel
{
	constructor(page)
	{
		this.page = page;
		this.element = page.element;

		this.charts_data = ko.observableArray([]);
		this.no_data = ko.observable(false);
	}

	async load ()
	{
		let data = await Grape.fetches.getJSON('/api/record', {schema: 'echarts', table: 'v_charts'});
		if (data.status === 'OK' && data.records.length > 0)
		{
			let process = data.records.map(rec => {
				return {
					chart_id: rec.chart_id,
					chart_name: rec.name,
					/*type: rec.type,
					schema: rec.data_source_options.source_schema,
					table: rec.data_source_options.source_table*/
				}
			});
			this.charts_data(process);
			this.no_data(false);
		}
		else
			this.no_data(true);
	}

	async add_chart (data)
	{
		let response = await Grape.dialog.open('Chart', {chart: ''});
		if (response)
			console.log(response);
			//Grape.navigate(`/charts/chart-view/${String(this.chart_name())}/${String(chart_data.chart_id)}`);
			this.load();
	}

	async create_chart ()
	{
		
	}

	async delete_chart (data)
	{
		let response = await Grape.fetches.postJSON('/api/echarts/chart/delete', {chart_id: data});
		if (response)
			this.load();
	}

	view_chart (data)
	{
		Grape.navigate(`/charts/chart-view/${String(data.chart_name)}/${String(data.chart_id)}`);
	}
}

/**
 * page class
 */
class ChartsListPageClass
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new ChartsListPageViewModel(this);
	}

	async init() {
		document.title = 'Chart List'
		await this.viewModel.load();
	}

	async teardown() {

	}
}

export default {
	route: '[/]ui/charts/charts-list',
	page_class: ChartsListPageClass,
	template: template
}
