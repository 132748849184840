
// TODO: File provided as example only. Remove if not used along with associated template and folder

import template from './dialog.html';

/**
 * View Model
 */
class SampleDialogViewModel {
	/**
	 * ViewModel constructor.
	 * @param {SampleDialogClass} dialog - SampleDialog class
	 */
	constructor(dialog) {
		this.dialog = dialog;

		this.fname = ko.observable();
		this.lname = ko.observable();

		this.titles = ko.observableArray();
		this.title = ko.observable();

		this.available_roles = ko.observableArray([{ name: 'Option 1', value: 1 }, { name: 'Option 2', value: 2 }, { name: 'Option 3', value: 3 }]);
		this.selected_roles = ko.observableArray();
	}

	/**
	 * Handle click event for save button
	 */
	save_click() {
		this.dialog.close({
			fname: this.fname(),
			lname: this.lname(),
			title: this.title(),
			roles: this.selected_roles()
		});
	}

	/**
	 * Handle click event for close button
	 */
	close_click() {
		this.dialog.close(false);
	}
}

/**
 * SampleDialog
 */
class SampleDialogClass {
	/**
	 * constructor
	 * @param {Object} bindings -
	 */
	constructor(bindings, element) {
		this.bindings = bindings;
		this.element = element;

		this.viewModel = new SampleDialogViewModel(this); // Name of the ViewModel defined above
	}

	get name() { return 'SampleDialog'; }

	async init() {
		let person_titles = await Grape.cache.get('PersonTitleLookup');
		this.viewModel.titles(person_titles);

		// Update select2 dropdown boxes
		$('.select2', this.element).select2();
	};
}

export default {
	name: 'SampleDialog',
	dialog_class: SampleDialogClass,
	template: template
};
